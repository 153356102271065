import "./styles/Card.css";

import { Link } from "react-router-dom";

function Card({ heading, img, text, button_text, col_len, pad, btn_link }) {
  return (
    <div className={`Card col-lg-${col_len} col-md-6`}>
      <div className={`inner ${pad ? "pad" : ""}`}>
        <Link to={btn_link}>
          <img
            src={img}
            alt={heading}
            className="w-100 img-fluid"
            style={{
              height: `${window.innerWidth >= 768 ? "200px" : "350px"}`,
            }}
          />
        </Link>
        <div className="card-content">
          <h1>{heading}</h1>
          <p>{text}</p>
          <div className="d-flex align-items-center">
            <Link to={btn_link}>{button_text}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
