import { Link } from "react-router-dom";

import "./styles/Section.css";

function Section({ name, text, btn_link, img, btn_text }) {
  return (
    <section className="Section container p-0 bg-light">
      <div className="row p-0 m-0" id="section-container">
        <div className="col-lg-6 col-md-6 p-0">
          <div className="section-items">
            <h1>{name}</h1>
            <p>{text}</p>
            <Link to={btn_link} className="btn btn-dark px-4">
              {btn_text}
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 py-0 px-5 d-flex justify-content-center align-items-center">
          <img src={img} alt={name} className="img-fluid" />
        </div>
      </div>
    </section>
  );
}

export default Section;
