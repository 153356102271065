import "./styles/Slide.css";

import { Link } from "react-router-dom";

function Slide({ obj }) {
  return (
    <div className="Slide row">
      <div className="col-sm-12 col-md-6 col-lg-6 header-next">
        <div
          className={`slide-items d-flex flex-column justify-content-center h-100 w-75 mx-auto ${
            window.innerWidth < 768 ? "center-self" : ""
          }`}
        >
          <h1>{obj.heading}</h1>
          <p>{obj.text}</p>
          <Link
            to={obj.btn_link}
            className={`btn btn-dark px-4 ${
              window.innerWidth < 768 ? "center-self" : ""
            }`}
          >
            {obj.button_text}
          </Link>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-6 m-0 p-0 header-next d-flex justify-content-center align-items-center">
        <img src={obj.img} alt={obj.heading} className="img-fluid" />
      </div>
    </div>
  );
}

export default Slide;
