import "./styles/About.css";
import React from "react";

import Content from "../components/Content";
import content_bottom from "../data/content-bottom.json";

import people_working from "../img/people-working.jpg";

class AboutPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="About">
        <section className="container my-5">
          <div className="about-content row">
            <div className="col-lg-5 col-md-12">
              <h1 className="mb-4">Ko smo mi</h1>
              <p>
                Skycom je firma koja se bavi uslugama iz oblasti informacionih
                tehnologija duži niz godina. Višegodišnje iskustvo u ovoj
                oblasti garantuje kvalitet i profesionalnost naših usluga.
                Nastojimo da sa našim klijentima, pored ponude proizvoda i
                usluga koje će zadovoljiti nijihove potrebe, ostvarimo dugoročnu
                saradnju. Posebnu pažnju posvećujemo poslovnoj etici, kako u
                svakodnevnom poslovanju sa klijentima, tako i u odnosu prema
                zaposlenima. Naši zaposleni poseduju znanja i veštine što
                potvrdjuju i brojni zadovoljni klijenti. Konstantno usavršavanje
                i praćenje trendova u informacionim tehnologijama ostvarujemo
                kroz kontinuiranu edukaciju naših zaposlenih pohadjanjem brojnih
                kurseva, seminara, obuka i sertifikacija kao sto su: CISCO,
                COMPTIA, MCSA…
              </p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-6 col-md-12">
              <img
                src={people_working}
                alt="ljudi rade"
                className="img-fluid h-100"
              />
            </div>
          </div>
          <div>
            <h4 className="mt-5">Ponude</h4>
            <Content content={content_bottom.data} />
          </div>
        </section>
      </div>
    );
  }
}

export default AboutPage;
