import "./styles/Content.css";

import Card from "./Card";

function Content({ content }) {
  const calculatePadding = (width, idx) => {
    if (width >= 992) {
      return idx === content.length - 1 ? false : true;
    }
    if (width >= 768) {
      return idx % 2 === 0 ? true : false;
    }
    return 0;
  };

  return (
    <div className="Content container">
      <div className="row">
        {content.map((obj, idx) => (
          <Card
            key={`Card-${idx}`}
            heading={obj.heading}
            text={obj.text}
            img={obj.img}
            button_text={obj.btn_text}
            col_len={parseInt(12 / content.length)}
            btn_link={obj.btn_link}
            pad={calculatePadding(window.innerWidth, idx)}
          />
        ))}
      </div>
    </div>
  );
}

export default Content;
