import { useEffect, useState } from "react";
import "./styles/Gallery.css";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

export default function Gallery() {
  const [gallery, setGallery] = useState([]);
  const [imgIdx, setImgIdx] = useState(0);

  function importAll(r) {
    return r.keys().map(r);
  }

  function handleClick(n) {
    let next = imgIdx + n;
    if (next > gallery.length - 1) next = 0;
    else if (next < 0) next = gallery.length - 1;
    else {
      const img = document.getElementById("gallery-img");
      img.src = gallery[next];
    }
    setImgIdx(next);
  }

  useEffect(() => {
    const images = importAll(
      require.context("../img/gallery", false, /\.(png|jpe?g|svg)$/)
    );
    setGallery(images);
  }, []);
  return (
    <div className="Gallery">
      <div className="gallery-items">
        <button id="gallery-left" onClick={() => handleClick(-1)}>
          <AiOutlineLeft />
        </button>
        <button id="gallery-right" onClick={() => handleClick(1)}>
          <AiOutlineRight />
        </button>
        {gallery.length > 0 && (
          <img
            className="img-fluid"
            id="gallery-img"
            src={gallery[imgIdx]}
            alt="slika iz galerije"
          />
        )}
      </div>
    </div>
  );
}
