import React from "react";
import { Link } from "react-router-dom";
import "./styles/Offers.css";

class OffersPage extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="Offers">
        <div className="container">
          <div className="content">
            <h1>Dolazi uskoro</h1>
            <Link to="/">Početna</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default OffersPage;
