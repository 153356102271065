import "./styles/NotFound.css";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="NotFound">
      <div className="container">
        <div>
          <h1 className="text-center">Izgleda da ste se izgubili</h1>
          <h4 className="text-center">Stranica koju tražite nije pronadjena</h4>
        </div>
        <h1 className="text-center">4 0 4</h1>
        <div className="d-flex justify-content-center not-found-links">
          <Link to="/">Početna</Link>
          <Link to="/o-nama">O Nama</Link>
          <Link to="/ponude">Ponude</Link>
          <Link to="/kontakt">Kontakt</Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
