import Content from "./Content";
import Section from "./Section";

import content_top from "../data/content-top.json";
import content_bottom from "../data/content-bottom.json";
import skycom_logo from "../img/skycom_logo.png";
import References from "./References";

function Main() {
  const o_nama_txt =
    "Usluge informacionih tehnologija, web developmenta, servisiranja računara, štampača i više. Sve za vaš biznis na jednom mestu.";

  return (
    <main className="Main">
      <Content content={content_top.data} />
      <Section
        name="SKYCOMIT"
        text={o_nama_txt}
        btn_text="Kontakt"
        btn_link="/kontakt"
        img={skycom_logo}
      />
      <Content content={content_bottom.data} />
      <References />
    </main>
  );
}

export default Main;
