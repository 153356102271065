import "./styles/Navbar.css";

import { Link } from "react-router-dom";

import skycom_logo from "../img/skycom_logo.png";

function Navbar() {
  return (
    <nav className="Navbar container navbar navbar-expand-lg navbar-light">
      <Link className="navbar-brand logo" to="/">
        <img src={skycom_logo} className="img-fluid" alt="Skycom logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/o-nama">
              O nama
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/ponude">
              Ponude
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Više
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link className="dropdown-item" to="/galerija">
                Galerija
              </Link>
              <a className="dropdown-item" href="/?q=ref">
                Reference
              </a>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/kontakt">
                Podaci o firmi
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/kontakt">
              Kontakt
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
