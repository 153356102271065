import { useEffect, useState } from "react";
import "./styles/Header.css";

import data from "../data/header-data.json";
import header_servis from "../img/header-servis.png";
import printer from "../img/printer.png";
import header_av from "../img/header-av.png";

import Slide from "./Slide";

import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

function Header() {
  const [slideIdx, setSlideIdx] = useState(1);
  const [canAnimate, setCanAnimate] = useState(true);
  const [slidesNum, setSlidesNum] = useState([]);

  data.data[0].img = header_servis;
  data.data[1].img = header_av;
  data.data[2].img = printer;

  const showSlides = (n) => {
    let i;
    let slides = document.getElementsByClassName("Slide");
    let dots = document.getElementsByClassName("dot");

    if (n > slides.length) n = 1;
    if (n < 1) n = slides.length;

    for (i = 0; i < slides.length; i++) slides[i].style.display = "none";
    if (dots.length)
      for (i = 0; i < dots.length; i++)
        dots[i].className = dots[i].className.replace(" active", "");

    slides[n - 1].style.display = "flex";
    if (dots.length) dots[n - 1].className += " active";
    setSlideIdx(n);
    let sidxs = [];
    for (let i = 1; i <= slides.length; i++) sidxs.push(i);
    setSlidesNum(sidxs);
  };

  const plusSlides = (n) => {
    showSlides(slideIdx + n);
  };

  const currSlide = (n) => {
    showSlides(n);
  };

  useEffect(() => {
    showSlides(slideIdx);
    const intv = setInterval(() => {
      if (canAnimate) {
        plusSlides(1);
      }
    }, 5000);

    return () => {
      clearInterval(intv);
    };
  }, [slideIdx, canAnimate]);

  return (
    <header
      className="Header container bg-light"
      onMouseEnter={() => {
        setCanAnimate(false);
      }}
      onMouseLeave={() => {
        setCanAnimate(true);
      }}
    >
      {data.data.map((obj, idx) => {
        return <Slide key={`Slide-${idx}`} obj={obj} />;
      })}
      <button className="prev" onClick={() => plusSlides(-1)}>
        <AiOutlineLeft />
      </button>
      <button className="next" onClick={() => plusSlides(1)}>
        <AiOutlineRight />
      </button>
      <div className="dots">
        {slidesNum.map((slideIdx) => (
          <div
            className={`dot ${slideIdx === 1 ? "active" : ""}`}
            onClick={() => currSlide(slideIdx)}
            key={`dot ${slideIdx}`}
          ></div>
        ))}
      </div>
    </header>
  );
}

export default Header;
