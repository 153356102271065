import "./styles/Contact.css";
import React from "react";

import Content from "../components/Content";
import content_top from "../data/content-top.json";

import { FaPhone, FaMailBulk, FaLocationArrow } from "react-icons/fa";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Contact">
        <section className="container">
          <div className="row my-5">
            <div className="col-md-7">
              <h2>Kontakt</h2>
              <form action="/">
                <div className="row my-3">
                  <div className="col-md-6">
                    <label htmlFor="name">Vaše Ime</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-12">
                    <label htmlFor="subject">Tema</label>
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-12">
                    <label htmlFor="message">Poruka</label>
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="2"
                      autoComplete="off"
                    ></textarea>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-3">
                    <button
                      className="btn btn-dark my-3 px-4"
                      onClick={(e) => {
                        e.preventDefault();
                        alert(
                          "Ova akcija trenutno nije dostupna. Koristite našu email adresu u medjuvremenu"
                        );
                      }}
                    >
                      Pošalji
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-1" />
            <div className="contact-info col-md-4">
              <h3>Podaci o firmi</h3>
              <div className="row my-3">
                <div className="col-md-12">
                  <span>PIB</span>
                  <span>112868854</span>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-12">
                  <span>MB</span>
                  <span>66407942</span>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-12">
                  <span>TR</span>
                  <span>265-1100310079685-02 Raiffeisen Banka</span>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-12">
                  <span>
                    <FaPhone />
                  </span>
                  <span>+381 64 226 2056</span>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-12">
                  <span>
                    <FaLocationArrow />
                  </span>
                  <span>Vladike Gavrila Popovića 6, 15000 Šabac</span>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-12">
                  <span>
                    <FaMailBulk />
                  </span>
                  <span>skycom015@gmail.com</span>
                  <span>office@skycomit.rs</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <h4>Više</h4>
          <Content content={content_top.data} />
        </div>
      </div>
    );
  }
}

export default ContactPage;
