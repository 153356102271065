import "./styles/Footer.css";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="Footer bg-light">
      <div className="container">
        <div className="row">
          <div className="footer-top py-3">
            <div className="col-lg-4">
              <ul>
                <h5>Linkovi</h5>
                <li>
                  <Link to="/o-nama">Ko smo mi</Link>
                </li>
                <li>
                  <Link to="/ponude">Ponude</Link>
                </li>
                <li>
                  <Link to="/galerija">Galerija</Link>
                </li>
                <li>
                  <Link to="/kontakt">Podaci o firmi</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
              <ul>
                <h5>Ponude</h5>
                <li>
                  <Link to="/ponude?q=mreza">Održavanje računarskih mreža</Link>
                </li>
                <li>
                  <Link to="/ponude?q=backup">Backup podataka</Link>
                </li>
                <li>
                  <Link to="/ponude?q=tehnicka-podrska">Tehnička podrška</Link>
                </li>
                <li>
                  <Link to="/ponude">Sve ponude</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 d-flex justify-content-end">
              <ul>
                <h5>Kontakt</h5>
                <li>+381 64 226 205 6</li>
                <li>office@skycomit.rs</li>
                <li>
                  Vladike Gavrila Popovića 6,
                  <br />
                  15000 Šabac
                </li>
                <li>
                  <Link to="/kontakt">Obratite nam se</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row py-3 footer-bottom">
          <div className="col-lg-8">
            <h6>SKYCOM</h6>
          </div>
          <div className="col-lg-4">
            <ul className="d-flex justify-content-between">
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
              <li>
                <Link to="/privatnost">Privatnost</Link>
              </li>
              <li>
                <Link to="/uslovi-koriscenja">Uslovi Koriscenja</Link>
              </li>
              <li>&copy; Skycomit 2023</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
