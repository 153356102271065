import { useState, useEffect } from "react";
import "./styles/References.css";

export default function References() {
  const [references, setReferences] = useState([]);
  const [allLinks, setAllLinks] = useState([]);
  const logoMapping = {
    avlija_bogatic: "https://avlijabogatic.rs/",
    axel_service: "https://aksel.co.rs/",
    caritas_sabac: "https://caritas-sabac.com/",
    cedanit: "https://cedanit.co.rs/",
    csr_sabac: "https://csrsabac.org.rs/",
    csu_sabac: "https://csusabac.rs/",
    dom_zdravlja_sabac: "https://www.dzsabac.org.rs/",
    kajak_klub_zorka: "https://www.kkzorka.rs/",
    pu_nase_dete: "https://nasedete.rs/",
    socijalna_sinergija: "https://socijalnasinergija.rs/",
    sportski_savez_sabac: "https://sportsabac.org.rs/",
    zrk_medicinar: "http://www.zrkmedicinar.rs/",
  };

  function importAll(r) {
    return r.keys().map(r);
  }

  useEffect(() => {
    const images = importAll(
      require.context("../img/logos", false, /\.(png|jpe?g|svg)$/)
    );
    setAllLinks(images);
    let end = 3;
    setReferences(images.slice(0, end));
    const intv = setInterval(() => {
      setReferences(images.slice(end, end + 3));
      end += 3;
      if (end >= 12) end = 0;
    }, 2500);
    return () => {
      clearInterval(intv);
    };
  }, []);

  return (
    <div className="References">
      <div className="container">
        <div className="references-container">
          {references.map((r, idx) => {
            const t = "media/";
            const start = r.indexOf(t) + t.length;
            let res = "";
            for (let i = start; i < r.length; i++) {
              if (r[i] === ".") break;
              res += r[i];
            }

            return (
              <a
                key={`Reference ${idx}`}
                href={logoMapping[res]}
                target="_blank"
              >
                <div className="ref-img-cont">
                  <img src={r} alt={`${res} logo`} className="img-fluid" />
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
