import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Footer from "./components/Footer";

import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import OffersPage from "./pages/OffersPage";

import "./App.css";
import NotFound from "./pages/NotFoundPage";
import Gallery from "./pages/Gallery";

function App() {
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.get("q") === "ref") {
      const ref = document.querySelector(".References").getBoundingClientRect();
      window.scrollTo(ref.x, ref.y);
    }
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/kontakt" element={<ContactPage />} />
          <Route path="/o-nama" element={<AboutPage />} />
          <Route path="/ponude" element={<OffersPage />} />
          <Route path="/galerija" element={<Gallery />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

function Body() {
  return (
    <>
      <Header />
      <Main />
    </>
  );
}

export default App;
